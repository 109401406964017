/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: whitesmoke;
}

.bm-burger-bars-light {
    background: #838383;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #3a95db;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #e0e3e6;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0;
}

/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    display: flex;
    flex-direction: column;
    color: #b8b7ad;
    padding: 0.8em;

    a,
    .menu-item.parent,
    a:hover {
        color: rgb(240, 240, 240);
        font-family: 'Inter', sans-serif;
        font-weight: 800;
        font-size: 1.4rem;
        padding-bottom: 15px;
        padding-top: 15px;
        text-transform: uppercase;
    }

    .menu-subitem,
    .menu-subitem:hover {
        font-size: 1.2em;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-transform: none;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        text-transform: uppercase;
    }


    .submenu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 80%;
    }
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    position: fixed !important;
    z-index: 1000 !important;
    top: 0px !important;
    left: 0px !important;
    height: 100vh !important;
    background: rgba(0, 0, 0, 0.5) !important;
    opacity: 1 !important;
    transition: opacity 0.3s ease 0s !important;
    width: 100vw !important;
}
