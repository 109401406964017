.icon-button {
    background-color: unset !important;
    color: var(--secondary-color) !important;
    padding: unset !important;
}

.borderless-button {
    background-color: unset !important;
    color: var(--secondary-color) !important;
}

.button.custom-button {
    color: white !important;
    background-color: var(--primary-color) !important;

    &.secondary-color {
        background-color: var(--secondary-color) !important;
    }

    &.wire {
        // background-color: var(--secondary-color) !important;
        background-color: unset !important;
        color: var(--secondary-color) !important;
        box-shadow: 0 0 0 1px var(--secondary-color) inset;
    }

    &.text {
        background-color: unset !important;
        color: unset !important;
    }
}

.slick-dots {
    bottom: -35px !important;

    li {
        margin: 0 2px !important;
    }
}
