.container {
    flex: 1;
    width: 100%;
    background-color: var(--tertiary-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.children {
    flex: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: 1200px;
    background-image: url('../../../../assets/images/conf-bg-darker.jpg');
    background-size: contain; 
    background-position: center;
    background-repeat: repeat-y;
}

.collaboration {
    display: flex;
    flex: 1.7;
    padding: 10px 20px 10px 20px;
    justify-content: center;
    align-items: center;
}

.collabImg {
    margin-top: 10px;
    height: 300;
    margin-left: -6px;
    object-fit: contain;
}

.pillarContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    justify-content: center;
    align-items: center;
}

.pillar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 600px;
    margin: 20px 0 20px 0;

    .xo {
        height: 40px;
        width: 45px;
        object-fit: contain;
        margin-bottom: 5px;
    }

    img {
        height: 42px;
        width: 35px;
        object-fit: contain;
        margin-bottom: 5px;
    }

    p {
        color: white;
        font-family: 'Inter', sans-serif;
        font-size: 0.95rem;
        line-height: 1.5;
        text-align: center;
    }
}
