.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1280px;
}

.video {
    display: flex;
    position: relative;
    z-index: 3;
    width: 85vw;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;


    video {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 20px 25px, rgba(0, 0, 0, 0.08) 0px -8px 15px,
            rgba(0, 0, 0, 0.08) 0px 2px 3px, rgba(0, 0, 0, 0.10) 0px 6px 7px, rgba(0, 0, 0, 0.05) 0px -2px 3px;
    }
}

.analytics {
    display: flex;
    flex-direction: column;
    margin: 50px 0 0 0;
    padding: 40px 0 80px 0;
    flex: 1;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background-color: #011627;
    border-top: 7px solid #2f9ef2;
    border-bottom: 7px solid #2f9ef2;

    h1 {
        color: #fff;
        line-height: 12px;
        font-size: 1.3rem;
        margin-bottom: -10px;
    }

    h2 {
        font-family: 'Inter';
        font-size: 1.8rem;
        line-height: 12px;
        color: #2f9ef2;
        margin-bottom: 50px;
    }
}

.analyticsVideo {
    display: flex;
    position: relative;
    z-index: 3;
    width: 90%;
    max-width: 700px;
    justify-content: center;
    align-items: center;

    video {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
            rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
}

.demo {
    max-width: 90% !important;
    object-fit: contain;
    margin-top: -200px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.description {
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 1280px;
    max-height: 380px;
}

.descriptionContainer {
    display: flex;
    flex-direction: column;
    margin: 100px 0 0 0;
    padding: 20px 0;
    flex: 1;
    width: 100vw;
    z-index: 10;
    justify-content: center;
    min-height: 500px;
    background-color: #161616;
    background-image: url('../../assets/images/textures/particles.svg');
}

.descriptionText {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1em;
    justify-content: center;

    h2 {
        color: var(--accent-color);
        font-weight: 700;
        font-family: 'Inter', sans-serif;
        margin-bottom: 0;
    }

    p {
        max-width: 900px;
        margin-bottom: 0.25em;
        color: #dddddd;
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        line-height: 1.5;
    }
}

.pillars {

    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    margin-top: 50px;
    background-color: var(--tertiary-color);
    background-image: url('../../assets/images/textures/particles.svg');

    .pillarText{
        margin-top: 30px;
        margin-bottom: 30px;
        max-width: 1280px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
            color: var(--accent-color);
            font-weight: 700;
            font-family: 'Inter', sans-serif;
            margin-bottom: 0;
        }

        p {
            color: white;
            padding-bottom: 30px;
            font-size: 1.05rem;
        }
    }
}

.proContentContainer {
    display: flex;
    padding: 20px 0;
    flex: 1;
    width: 100%;
    justify-content: center;
    background-color: white;
}

.proContent {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    max-width: 1150px;
}

.proDescription {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 2em;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    margin-left: 10%;
    margin-right: 10%;
    
    h2 {
        color: var(--accent-color);
        font-weight: 800;
        font-size: 2.2rem;
        font-family: 'Inter', sans-serif;
        margin-bottom: 0;
        margin-bottom: 15px;
    }

    p {
        max-width: 500px;
        margin-bottom: 1em;
        color: var(--tertiary-color);
        font-family: 'Inter', sans-serif;
        font-size: 1.1rem;
        line-height: 1.5;
    }
}

.proVideoContainer {
    flex: 1.25;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        padding: 1.3em;
        width: 100%;
    }
}

.headline {
    position: relative;
    height: 70vh;
    width: 100vw;
    background-color: var(--tertiary-color);
    background-image: url('../../assets/images/textures/particles.svg');
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;


    .textContainer {
        width: 75%;
        display: flex;
        height: -100px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h2 {
            font-family: 'Inter', sans-serif;
            font-weight: 700;
            font-size: 2.2rem;
            width: 100%;
            margin-top: 0 auto;
            text-align: center;
            color: #ffffff;
            z-index: 5;
        }

        .backgroundImage {
            position: absolute;
            z-index: 3;
            top: 15%;
            left: 5%;
            transform: translateX(-60%);
            transform: scale(1.8);
            opacity: 0.2; 
            overflow-x: hidden;
            max-width: 100vw;
        }
    }

    

    .particles {
        position: absolute !important;
        left: 0 !important;
        opacity: 0.7;
        top: -5em;
        z-index: 1;
        width: 100vw;
        object-fit: cover;
        height: 450px;
    }
}

.apiDescription {
    display: flex;
    flex: 1;
    width: 100%;
}

.apiContainer {
    display: flex;
    flex-direction: column;
    padding: 5% 0;
    flex: 1;
    width: 100%;
    justify-content: center;
    background-color: #ffffff;
}

.apiGraphic {
    flex: 1.25;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        padding: 1.3em;
        width: 80vw;
    }
}

.apiDescriptionText {
    flex: 1;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    flex-direction: column;
    padding: 2em;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    h2 {
        color: var(--accent-color);
        font-weight: 800;
        font-size: 2.6rem;
        font-family: 'Inter', sans-serif;
        max-width: 90%;
    }

    p {
        max-width: 90%;
        margin-bottom: 1.2em;
        color: var(--tertiary-color);
        font-family: 'Inter', sans-serif;
        font-size: 1.3rem;
        line-height: 1.5;
    }
}

.apiSpan {
    font-weight: 800;
    background-image: url('../../assets/images/textures/marker-smudge.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 45px;
    font-style: italic;
    color: #ffffff;
}

.underlineSpan {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: -115px;
    height: 300px;
    width: 100%;
    background: url('../../assets/images/textures/underline-blue.png') no-repeat center;
    background-size: contain;
}

.underlineSpanTop{
    display: inline-block;
    position: relative;
    padding-bottom: 10px;
    font-style: italic;
    font-weight: 1000;
}

.clientSpan {
    font-weight: 900;
    background-image: url('../../assets/images/textures/marker-smudge.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 25px;
}