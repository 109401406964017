.container {
    display: flex;
    flex-direction: column;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)),
        url('../../../../assets/images/textures/ai-header-lg.png');
    background-size: auto 100%;
    background-repeat: no-repeat;

    h1 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 1.4rem;
    }

    h2 {
        font-family: 'Inter', sans-serif;
        font-weight: 800;
        font-size: 1.7rem;
        margin-top: 0;
        text-align: center;
    }
}

.headline {
    font-family: 'Roboto', sans-serif;
    color: #023c6b;
    font-weight: 800;
    font-size: 2.5rem;
    line-height: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0px 50px 0px;
}

.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.video {
    padding: 0px 40px;
    width: 100%;
    flex: 0.8;
}

.learnMore {
    display: flex;
    flex: 0.3;
    min-height: 70px;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 5px;

    p {
        margin-bottom: 0;
        margin-right: 10px;
        text-transform: uppercase;
        font-weight: 900;
        color: var(--primary-color);
        font-size: 1.6rem;
    }
}

.learnMore:hover {
    cursor: pointer;

    p {
        transition: all 0.2s ease-in-out;
        color: var(--accent-color);
    }

    i {
        transition: all 0.2s ease-in-out;
        color: var(--accent-color) !important;
        box-shadow: 0 0 0 0.1em rgba(11, 132, 225, 0.745) inset !important;
    }
}

.learnMoreIcon {
    font-size: 1.2em !important;
    box-shadow: 0 0 0 0.1em rgba(0, 59, 104, 0.6) inset !important;
}
