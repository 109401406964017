.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 60px 0 60px 0;
    background-color: white;

    h2 {
        color: var(--primary-color);
        font-family: 'Inter', sans-serif;
        font-size: 2rem;
        font-weight: 800;
        margin-bottom: 40px;
    }
}

.card {
    position: relative;
    width: 100%;
    height: 100%;
}

.card img:hover {
    cursor: pointer;
    outline: 2px solid #18639d;
}

.team {
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;
    max-width: 1100px;
    flex-direction: column;
}

.row {
    display: flex;
    flex: 1;
    width: 100%;
    min-height: 250px;
    margin-bottom: 25px;
}

.person {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px 40px 10px;
    font-family: 'Inter', sans-serif;

    h3 {
        text-align: center;
        margin-top: 5px;
        margin-bottom: 0;
        font-weight: 600;
        font-size: 1.1rem;
        color: #18639d;
        margin-bottom: 3px;
    }

    h4 {
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 0.9rem;
        color: #242424;
        min-height: 35px;
    }

    img {
        max-width: 100%;
        padding-top: 10px;
    }
}

.bio {
    position: fixed;
    z-index: 200;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    justify-content: center !important;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);
}

.title {
    color: #18639d;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Inter';
}

.info {
    font-weight: 400;
    font-family: 'Inter';
    margin-bottom: 15px;
}

.bioContainer {
    margin-top: 10%;
    border-radius: 5px;
    padding: 10px 20px 5px 20px;
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-width: 450px;
    background-color: white;
}

.interview {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}
