.container {
    background-color: var(--tertiary-color);
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 375px;
    padding-top: 50px;
    width: 100%;

    h1 {
        color: var(--accent-color);
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 800;
        font-family: 'Inter', sans-serif;
    }

    h2 {
        margin-top: 0px;
        color: white;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        text-align: center;
    }
}

.verticalImg {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 250px !important;

    img {
        height: 53px !important;
    }
}
