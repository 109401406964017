.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 1050px;
    padding: 30px 20px 30px 20px;
    text-align: center;

    h2 {
        color: var(--primary-color);
        font-family: 'Inter', sans-serif;
        font-size: 2rem;
        font-weight: 800;
        margin-bottom: 40px;
    }
    p {
        color: #404040;
        line-height: 1.5;
        font-family: 'Inter', sans-serif;
        font-size: 1.1rem;
        font-weight: 500;
    }
}

.timeline {
    width: 90% !important;
    max-width: 400px !important;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0 10px 0 10px;
}