.container {
    position: relative;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    background-color: white;
    width: 100vw;
}

.logos {    
    padding-left: 1.5%;
    padding-right: 1.5%;
    height: 80px; 
    margin: 0 10px;
    object-fit: contain;
    transition: all .5s cubic-bezier(0.645, 0.045, 0.355, 1);

}
.logos:hover {
    transform: scale(1.1);
}

.marquee {
    display: flex;
    white-space: nowrap;
    margin-bottom: 60px;
}  
.marqueeContent {
    display: flex;
    animation: scroll 100s linear infinite;
}

@keyframes scroll {
0% { transform: translateX(0); }
100% { transform: translateX(-100%); }
}
