.container {
    flex: 1;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: var(--tertiary-color);
    overflow: hidden;
    position: relative;
    scroll-snap-align: start;
}

.form {
    position: relative;
    z-index: 10;
    max-width: 500px;
    width: 90vw;
    padding: 80px 0 80px 0;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    color: #2287d5;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
}

.success {
    text-align: center;
    color: white;
    font-family: 'Inter', sans-serif;
}

.header {
    color: white;
    font-weight: 800;
    font-size: 2rem;
    font-family: 'Inter', sans-serif;
    text-align: center;
}
.input {
    input {
        background-color: #152836 !important;
        border-radius: 0 !important;
        font-family: 'Inter', sans-serif !important;
        font-weight: 400 !important;
        color: white !important;
    }
    width: 100% !important;
    border-radius: 0 !important;
    margin-bottom: 5px;
}

.submit {
    width: 120px;
    background-color: transparent !important;
    border: 2px solid white !important;
    color: white !important;
    text-transform: uppercase !important;
    font-family: 'Inter', sans-serif !important;
    font-weight: 600 !important;
    height: 35px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 0 !important;
    margin-top: 5px !important;
    align-self: flex-end;
    margin-right: 0 !important;
}

.submit:hover {
    background-color: white !important;
    color: var(--tertiary-color) !important;
}

.demo {
    color: rgb(161, 161, 161);
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    font-size: 1.2rem;
    margin: 30px 0 30px 0;
}

.dots {
    position: absolute !important;
    left: 0 !important;
    opacity: 0.015;
    z-index: 1;
    width: 100vw;
    object-fit: cover;
    height: 100%;
    max-height: 100%;
}
