.container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 20px 5% 30px 5%;

    .content {
        color: whitesmoke;
        font-weight: 100;
        font-size: 1rem;
        font-family: 'Open Sans', sans-serif !important;
        line-height: 1.3;
        padding-bottom: 30px;
        width: 90%;
    }

    .learnBtn {
        width: 90% !important;
        display: flex !important;
        border-radius: 0 !important;
        background-color: transparent !important;
        border: 1px solid white !important;
        color: white !important;
        text-transform: uppercase !important;
        font-size: 1rem !important;
        font-weight: 300 !important;
        font-family: 'Open Sans', sans-serif !important;
        height: 30px !important;
        justify-content: center;
        align-items: center;
    }
}

.pillar {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    padding: 0 1em 0 1em;
    text-align: center;
    border-bottom: solid 1px rgba(207, 207, 207, 0.1);
    margin-bottom: 30px;
    padding-bottom: 30px;
    max-width: 500px;
}

.heading {
    background-color: white;
    color: white;
    width: 90%;
    padding: 8px;
    font-family: 'Inter';
    font-weight: 800;
    font-size: 1.3rem;
    border-radius: 3px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.last {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 2em 0 2em;
    max-width: 500px;
}
