.body {
    background-color: var(--tertiary-color);
}

.header {
    width: 100%;
    background-color: #f2f5f7 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 3rem;
    padding-top: 4rem;
    padding-bottom: 3rem;

    h1 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 1.4rem;
    }

    h2 {
        font-family: 'Inter', sans-serif;
        font-weight: 800;
        font-size: 1.7rem;
        margin-top: 0;
        text-align: center;
    }

    img {
        position: absolute !important;
        bottom: 0;
        right: 18%;
        width: 65%;
        min-width: 700px;
        opacity: 0.1;
        z-index: 1;
    }
    p {
        text-align: center;
        color: black;
        line-height: 1.5;
        font-family: 'Inter', sans-serif;
        font-size: 1.1rem;
        line-height: 1.5;
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

.graphicHeader {
    color: var(--tertiary-color);
    padding-bottom: 2rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 800;
    font-family: 'Inter', sans-serif;
}

.collegeRecruitDiv {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    width: 100%;
}

.afcaHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 30px;
    padding-left: 1rem;
    padding-right: 1rem;
    h1 {
        font-family: 'Inter', sans-serif;
        text-align: center;
        font-weight: 800;
        font-style: italic;
        font-size: 2rem;
        color: var(--primary-color);
    }
}

.afcaBoothNumber {
    // background-image: url(../../assets/images/textures/underline-blue.png) no-repeat center;
    background-size: contain;
}

.collegeRecruitContent {
    display: flex;
    flex-direction: column;
    width: 65%;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 20px;
}

.collegeRecruitContainers {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 200px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #1e2f3f;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    h1 {
        color: var(--accent-color);
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 800;
        font-family: 'Inter', sans-serif;
    }

    h2 {
        margin-top: 0px;
        color: white;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        text-align: center;
    }
}

.listStyling {
    ul {
        padding-inline-start: 15px;
        padding-right: 5px;
        justify-content: 'flex-start';
        margin-bottom: 1em;
        color: #dddddd;
        font-family: 'Inter', sans-serif;
        font-size: 1.1rem;
        line-height: 1.5;
    }
}

.learnStyling {
    justify-content: center;
    width: 100%;
    height: 33px;
}

.learnBtn {
    width: 90% !important;
    display: flex !important;
    border-radius: 20 !important;
    background-color: white !important;
    color: var(--primary-color) !important;
    text-transform: uppercase !important;
    font-size: 1.25rem !important;
    font-weight: 800 !important;
    font-family: 'Inter', sans-serif !important;
    height: 30px !important;
    justify-content: center;
    align-items: center;
}

.learnBtn:hover {
    background-color: var(--primary-color) !important;
    color: white !important;
    font-weight: 600 !important;
}

.jotformContent {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 10px;
    margin-bottom: 25px;
    z-index: 9999;
}

.jotformContainer {
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    width: 100%;
    background-color: #252525;
    padding: 10px;
    border: 1px solid #ffffff24;

    h1 {
        color: #fcfcfc;
        margin: 0;
        font-size: 1.5rem;
        font-weight: 800;
        font-family: 'Inter', sans-serif;
        text-align: center;
    }

    h2 {
        padding-top: 5px;
        padding-bottom: 15px;
        margin: 0;
        color: rgb(122, 122, 122);
        font-size: 1.4rem;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
        text-align: center;
    }
}

.jotformDiv {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 10px;
    h2 {
        font-size: 1.1rem;
    }
}

.submitDiv {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
}

.submitPlayer {
    height: 40px;
    max-height: 40px;
    background-color: var(--accent-color) !important;
    color: white !important;
    font-family: 'Inter' !important;
    font-size: 1rem !important;
}

.submitPlayer:hover {
    background-color: #ffffff !important;
    color: var(--secondary-color) !important;
    font-weight: 800 !important;
}
