.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1280px;
}

.video {
    display: flex;
    position: relative;
    z-index: 3;
    width: 90%;
    max-width: 700px;
    justify-content: center;
    align-items: center;
}

.demo {
    max-width: 90% !important;
    object-fit: contain;
    margin-top: -200px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.description {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    max-width: 1050px;
    background-color: var(--tertiary-color);
    span {
        color: var(--primary-color);
        font-weight: 600;
    }
}

.graphic {
    flex: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;

    img {
        padding: 1.3em;
        width: 100%;
    }
}

.descriptionText {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    align-items: center;
    h2 {
        color: var(--accent-color);
        font-weight: 800;
        font-size: 2.2rem;
        font-family: 'Inter', sans-serif;
        margin-bottom: 0;
        margin-bottom: 15px;
    }
    p {
        max-width: 500px;
        margin-bottom: 1.5em;
        color: #ffffff;
        font-family: 'Inter', sans-serif;
        font-size: 1.1rem;
        line-height: 1.5;
    }
}

.pillars {
    max-width: 1050px;
    padding-top: 40px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10px;
    background-color: #ffffff;

    h2 {
        color: var(--accent-color);
        font-weight: 700;
        font-family: 'Inter', sans-serif;
        margin-bottom: 0;
    }

    p {
        padding-bottom: 30px;
        color: var(--tertiary-color);
    }
}

.transferPortal, .rosterManagement {
    background-color: #ffffff;
    padding: 30px 10px 30px 10px;
    h2 {
        color: var(--accent-color);
        font-weight: 800;
        font-size: 2rem;
        font-family: 'Inter', sans-serif;
        margin-bottom: 0;
        margin-bottom: 15px;
        text-align: center;
    }
}

.rosterManagement {
    padding-top: 10px;
}

.transferPortalContainer, .rosterManagementContainer {
    padding: 10px 20px 10px 20px;

    p {
        padding-bottom: 10px;
    }
}

.transferPortalText, .rosterManagementText {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0px 10px 10px 10px;
    text-align: center;
}

.transferPortalContentContainer, .rosterManagementContentContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.mainImg {
    margin-bottom: 10px;
    outline: 1px solid #161616;
}

.headline {
    width: 100%;
    height: 300px;
    background-color: var(--tertiary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    h1 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 1.4rem;
    }

    h2 {
        font-family: 'Inter', sans-serif;
        font-weight: 800;
        font-size: 1.4rem;
        margin-top: 0;
        text-align: center;
        color: #ffffff;
        z-index: 2;
    }

    img {
        position: absolute !important;
        bottom: 0;
        left: 0 !important;
        z-index: 1;
        width: 100vw;
        opacity: 0.3;
        top: -150px;
        object-fit: cover;
        height: 450px;
    }
}

.mobileHeaders {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
