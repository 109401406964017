.container {
    flex: 1;
    display: flex;
    width: 100%;
    margin-top: -115px;
    padding-top: 115px;
    background-color: #f2f5f7;
}

.children {
    flex: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    width: 100%;
    height: 300px;
    background-color: #f2f5f7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    h1 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 1.4rem;
    }

    h2 {
        font-family: 'Inter', sans-serif;
        font-weight: 800;
        font-size: 1.2rem;
        margin-top: 0;
        text-align: center;
    }

    img {
        position: absolute !important;
        bottom: 0;
        left: -50%;
        transform: translateX(50%);
        opacity: 0.05;
        z-index: 1;
    }
}

.partners {
    display: flex;
    flex-direction: row;
    width: 60%;
    margin-top: 30px;
    justify-content: space-between;
}

.ncaaDiv {
    margin-left: 8px;
    h1 {
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
        margin-top: 0;
        text-align: right;
        margin-bottom: -0.1em
    }
    h2 {
        text-align: right;
        font-family: 'Open Sans', sans-serif;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1rem;
    }
}

.nflDiv {
    margin-right: 8px;
    h1 {
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
        margin-top: 0;
        text-align: left;
        margin-bottom: -0.1em
    }
    h2 {
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1rem;
    }
}