.container {
    position: relative;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
}

.wordmark {
    position: absolute !important;
    bottom: -100px !important;
    height: 600px !important;
    object-fit: cover !important;
    object-position: -10%;
    opacity: 0.1;
}

.dots {
    opacity: 0.7;
}

.graphicHeader {
    text-align: center;
    color: #18639d;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 800;
    margin-bottom: 20px;
}

.graphic {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 1050px;
    padding: 30px 20px 30px 20px;
    text-align: center;

    p {
        color: #404040;
        line-height: 1.5;
        font-family: 'Inter', sans-serif;
        font-size: 1.1rem;
        font-weight: 500;
    }
}

.graphicP{
    color: #404040;
    line-height: 1.5;
    font-family: 'Inter', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
}

.timeline {
    width: 85% !important;
    max-width: 400px !important;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0 10px 0 10px;
}