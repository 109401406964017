.container {
    display: flex;
    flex: 1;
    padding: 100px 5% 30px 5%;
    max-width: 1280px;

    .content {
        color: whitesmoke;
        font-weight: 100;
        font-size: 1rem;
        font-family: 'Open Sans', sans-serif !important;
        line-height: 1.3;
        min-height: 180px;
    }

    .learnBtn {
        width: 90% !important;
        display: flex !important;
        border-radius: 0 !important;
        background-color: transparent !important;
        border: 1px solid white !important;
        color: white !important;
        text-transform: uppercase !important;
        font-size: 1rem !important;
        font-weight: 300 !important;
        font-family: 'Open Sans', sans-serif !important;
        height: 30px !important;
        justify-content: center;
        align-items: center;
    }

    .learnBtn:hover {
        background-color: white !important;
        color: var(--tertiary-color) !important;
        font-weight: 600 !important;
    }
}

.pillar {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    padding: 0 2em 0 2em;
    border-right: solid 1px #333;
    text-align: center;
}

.heading {
    background-color: white;
    color: white;
    width: 90%;
    padding: 8px;
    font-family: 'Inter';
    font-weight: 800;
    font-size: 1.3rem;
    border-radius: 3px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.last {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 2em 0 2em;
}
