.react-select-container {
    width: 100% !important;
    border-radius: 0 !important;
    margin-bottom: 5px;
    border: none !important;
}

.react-select__control {
    background-color: #152836 !important;
    border: none !important;
    border-radius: 0 !important;
}

.react-select__control--is-focused {
    border-color: rgb(237, 16, 16) !important;
    box-shadow: 0 0 0 1px rgba(89, 132, 153, 0.844) !important;
}

.react-select__placeholder {
    padding-left: 5px !important;
    color: #adadad !important;
}

.react-select__multi-value {
    background-color: var(--accent-color) !important;
    color: white !important;
    overflow: visible !important;
}

.react-select__multi-value__label {
    color: white !important;
    font-family: 'Inter', sans-serif !important;
    font-weight: 600 !important;
}
