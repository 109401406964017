.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1280px;
}

.video {
    height: 100%;   
    width: 60%;
    video {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
            rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
}

.calendly {
    width: 40%;
    margin-bottom: 5px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
            rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

}

.videoCalendar{
    display: flex;
    flex-direction: row;
    max-width: 1500px;
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 50px;
}

.pillars {
    max-width: 1500px;
    margin-top: 35px;
    margin-left: 25px;
    margin-right: 25px;
    padding-bottom: 30px;

    h2 {
        color: var(--primary-color);
        font-weight: 700;
        font-family: 'Inter', sans-serif;
        margin-bottom: 0;
    }

    p {
        font-size: medium;
    }
}
