.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1280px;
}

.video {
    display: flex;
    position: relative;
    z-index: 3;
    width: 90%;
    max-width: 700px;
    justify-content: center;
    align-items: center;
    margin-top: -200px;

    video {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
            rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
}

.demo {
    max-width: 90% !important;
    object-fit: contain;
    margin-top: -200px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.description {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    max-width: 1050px;

    span {
        color: var(--primary-color);
        font-weight: 600;
    }
}

.graphic {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em 1em 40px 1em;

    video {
        border-radius: 4px;
        padding: 5px;
        background-color: var(--tertiary-color);
    }
}

.descriptionText {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    align-items: center;

    p {
        max-width: 500px;
        margin-bottom: 1.5em;
        color: #404040;
        font-family: 'Inter', sans-serif;
        font-size: 1.1rem;
        line-height: 1.5;
    }
}

.pillars {
    max-width: 1050px;
    padding-top: 40px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 10px;
    background-color: #161616;

    h2 {
        color: var(--accent-color);
        font-weight: 700;
        font-family: 'Inter', sans-serif;
        margin-bottom: 0;
    }

    p {
        padding-bottom: 30px;
        color: #dddddd;
    }
}
