.container {
    display: flex;
    min-height: 400px;
    padding: 20px 10% 40px 10%;
    background-color: rgb(27, 27, 27);
    box-shadow: inset 0 0 10px #000000;
    z-index: 20;
    position: relative;
}

.verticalContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        width: 90% !important;
        max-height: 125px !important;
        object-fit: contain !important;
    }
}

.social {
    color: white !important;
    margin: 0 10px 0 10px !important;
}
.social:hover {
    color: #2287d5 !important;
}

.productContainer {
    display: flex;
    flex-direction: column;
    width: 300px;
    border-left: 1px solid var(--primary-color);
    border-right: 1px solid var(--primary-color);
    justify-content: space-evenly;
    align-items: center;
    padding: 30px 0 30px 0;
}

.productHeader {
    font-family: 'Inter', sans-serif;
    font-weight: 800;
    font-size: 1.6rem;
    color: #2287d5;
}

.product,
.product a {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 1.3rem;
    color: #fff;
}

.product a:hover {
    color: #c0c0c0;
}

.contactContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', sans-serif;
    margin-left: 30px;
    margin-right: -30px;

    h2 {
        font-size: 1.3rem;
        color: #1c86d7;
    }

    p {
        text-align: center;
        color: white;
    }
}

.contact {
    margin-left: 20px !important;
    height: 40px !important;
    width: 165px !important;
    font-weight: 800 !important;
    padding: 3px 0 0 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #18639d !important;
    color: white !important;
    font-family: 'Inter' !important;
    font-size: 1.2rem !important;
}

.contact:hover {
    background-color: #ffffff !important;
    color: var(--primary-color) !important;
    font-weight: 800 !important;
}

.mobileContact {
    margin-top: 30px !important;
    height: 40px !important;
    width: 220px !important;
    font-weight: 800 !important;
    padding: 3px 0 0 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #18639d !important;
    color: white !important;
    font-family: 'Inter' !important;
    font-size: 1.2rem !important;
}
