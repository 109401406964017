.container {
    position: relative;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    width: 100vw;

    h2 {
        color: var(--primary-color);
        font-family: 'Inter', sans-serif;
        font-size: 2rem;
        font-weight: 800;
        margin-bottom: 40px;
    }
}

.infoSection {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 1050px;
    padding: 40px 0px 40px 0px;
    text-align: center;

    p {
        color: #404040;
        line-height: 1.5;
        font-family: 'Inter', sans-serif;
        font-size: 1.2rem;
        font-weight: 500;
    }
}

.timeline {
    width: 86% !important;
    max-width: 1200px !important;
    margin-bottom: 80px;
}