.container {
    position: relative;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    background-color: white;
    width: 100vw;
}

.wordmark {
    position: absolute !important;
    bottom: -100px !important;
    height: 600px !important;
    object-fit: cover !important;
    object-position: -10%;
    opacity: 0.1;
}

.dots {
    opacity: 0.7;
}

.aboutHeader {
    display: flex;
    flex-direction: row;
    height: 150px;
    justify-content: center;
}

.graphicHeader {
    text-align: center;
    display: flex;
    justify-content: center;
    color: #18639d;
    text-transform: uppercase;
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: 10px;
    width: 80%;
    align-items: center;
}

.numberStyles {
    font-size: 1.3rem;
    font-weight: 800;
    color: #18639d;
}


.graphic {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 1050px;
    padding: 80px 0px 60px 0px;
    text-align: center;
}

.graphicP {
    color: #404040;
    line-height: 1.5;
    font-family: 'Inter', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
}



.nflFlipCard, .ncaaFlipCard {
    position: relative;
    width: 100%;
    height: 100%;
    perspective: 1000px;
    cursor: pointer;

    .nflFlipCardFront,
    .nflFlipCardBack,
    .ncaaFlipCardFront,
    .ncaaFlipCardBack {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: transform 0.6s;
    }

    .nflFlipCardFront, .ncaaFlipCardFront {
        transform: rotateY(0);
        z-index: 2;
        align-items: flex-start;

        h1, h2{
            font-size: 3em;
            margin: 0;
            line-height: 1;
        }

        h1 {
            font-family: 'Inter', sans-serif;
            font-weight: 800;
            font-size: 5.5rem;
            margin-top: 0;
            text-align: right;
            margin-bottom: -0.1em
        }
        h2 {
            text-align: right;
            font-family: 'Open Sans', sans-serif;
            text-transform: uppercase;
            font-weight: 400;
            font-size: 2.5rem;
        }
    }

    .ncaaFlipCardFront {
        align-items: flex-end;
    }

    .nflFlipCardBack,
    .ncaaFlipCardBack {
        transform: rotateY(180deg);
        padding: 40px;
        z-index: 1;
    }

    &:hover .nflFlipCardFront,
    &:hover .ncaaFlipCardFront {
        transform: rotateY(-180deg);
    }

    &:hover .nflFlipCardBack,
    &:hover .ncaaFlipCardBack {
        transform: rotateY(0);
    }
}