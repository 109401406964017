.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 60px 0 60px 0;
    background-color: white;

    h2 {
        color: var(--primary-color);
        font-family: 'Inter', sans-serif;
        font-size: 2rem;
        font-weight: 800;
        margin-bottom: 40px;
    }
}

.team {
    display: flex;
    align-items: center;
    flex: 1;
    width: 80%;
    max-width: 1100px;
    flex-direction: column;
}

.row {
    display: flex;
    flex: 1;
    width: 100%;
}

.person {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 10px 40px 10px;
    font-family: 'Inter', sans-serif;

    h3 {
        text-align: center;
        margin-top: 5px;
        margin-bottom: 0;
        font-weight: 600;
        font-size: 1.1rem;
        color: #18639d;
        margin-bottom: 3px;
    }

    h4 {
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 400;
        font-size: 0.9rem;
        color: #242424;
        min-height: 35px;
    }
}
