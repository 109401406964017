.navContainer {
    background-color: var(--tertiary-color) !important;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    display: flex;
    height: 70px;
    padding: 33px 0 0 30px;
    justify-content: flex-start;
    align-items: center;
}

.navContainerLight {
    background-color: #f2f5f7 !important;
    display: flex;
    height: 70px;
    padding: 33px 0 0 30px;
    justify-content: flex-start;
    align-items: center;
}

.contact {
    height: 45px !important;
    width: 185px !important;
    font-weight: 800 !important;
    padding: 0 0 0 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #18639d !important;
    color: white !important;
    font-family: 'Inter' !important;
    font-size: 1.3rem !important;
    text-transform: uppercase !important;
}

.contactParent {
    margin-top: auto;
}
