.container {
    flex: 1;
    display: flex;
    width: 100%;
    margin-top: -115px;
    padding-top: 115px;
    background-color: #f2f5f7;
}

.children {
    flex: 1;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    width: 100%;
    height: 300px;
    background-color: #f2f5f7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    

    h1 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 1.4rem;
    }

    h2 {
        font-family: 'Inter', sans-serif;
        font-weight: 800;
        font-size: 1.8rem;
        margin-top: 0;
        text-align: center;
    }

    img {
        position: absolute !important;
        bottom: 0;
        right: 18%;
        width: 65%;
        min-width: 700px;
        opacity: 0.05;
        z-index: 1;
    }
}
