.nav-container {
    background-color: var(--tertiary-color) !important;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    display: flex;
    height: 115px !important;
    padding: 40px 100px 20px 100px;
    justify-content: center;
    align-items: center;

    .navigation {
        border-radius: unset !important;
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 100;

        .nav-item.item {
            font-family: 'Inter', sans-serif !important;
            font-weight: 600;
            letter-spacing: 0;
            font-size: 0.9rem;
            line-height: 20px;
            text-transform: uppercase;
        }

        .nav-dropdown-menu {
            background-color: rgba(198, 197, 197, 0.315) !important;
        }

        .nav-dropdown-item {
            font-family: 'Inter', sans-serif !important;
            font-weight: 600;
            letter-spacing: 0;
            font-size: 0.9rem;
            line-height: 20px;
            text-transform: uppercase;
            
        }
    }

    .ui.secondary.inverted.menu .dropdown.item:hover,
    .ui.secondary.inverted.menu .link.item:hover,
    .ui.secondary.inverted.menu a.item:hover {
        background: rgba(198, 197, 197, 0.315) !important;
    }

    .ui.menu .ui.dropdown.item .menu .item{
        &:hover{
            background-color: var(--accent-color) !important;
            color: #ffffff !important;
        }
        &.nav-dropdown-item.active{
            background-color: var(--accent-color) !important;
            color: #ffffff !important;
        }
    }

    .ui.secondary.inverted.menu .link.item,
    .ui.secondary.inverted.menu a.item {
        color: #ffffff !important;
    }

    .ui.secondary.inverted.menu .dropdown.item,
    .ui.secondary.inverted.menu .dropdown a.item {
        color: #ffffff !important;
    }

    .ui.menu .active.item {
        font-weight: 800 !important;
        background-color: rgba(141, 140, 140, 0.421) !important;
    }

    .ui.secondary.inverted.menu {
        align-items: center;
    }

    .ui.menu:last-child {
        margin: auto !important;
        margin-right: 0 !important;
    }
}

.limit {
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.nav-container-light {
    background-color: transparent !important;
    display: flex;
    height: 115px !important;
    padding: 40px 100px 20px 100px;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: relative;

    .navigation {
        border-radius: unset !important;
        display: flex;
        justify-content: space-between;

        .nav-item.item {
            font-family: 'Inter', sans-serif !important;
            font-weight: 600;
            letter-spacing: 0;
            font-size: 0.9rem;
            line-height: 20px;
            text-transform: uppercase;
        }

        .nav-dropdown-menu {
            background-color: #bed6e9aa !important;
        }

        .nav-dropdown-item {
            font-family: 'Inter', sans-serif !important;
            font-weight: 600;
            letter-spacing: 0;
            font-size: 0.9rem;
            line-height: 20px;
            text-transform: uppercase;
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .ui.menu .ui.dropdown.item .menu .item{
        color: var(--tertiary-color) !important;
        &:hover{
            color: white !important;
            background-color: var(--accent-color) !important;
        }
        &.active{
            color: white !important;
            background-color: var(--accent-color) !important;
        }
    }

    .ui.secondary.inverted.menu .link.item,
    .ui.secondary.inverted.menu a.item {
        color: var(--tertiary-color) !important;
    }

    .ui.secondary.inverted.menu .dropdown.item,
    .ui.secondary.inverted.menu .dropdown a.item {
        color: var(--tertiary-color) !important;
    }

    .ui.secondary.inverted.menu .link.item:hover,
    .ui.secondary.inverted.menu .dropdown.menu,
    .ui.secondary.inverted.menu a.item:hover {
        color: var(--primary-color) !important;
    }

    .ui.secondary.inverted.menu .dropdown.item:hover,
    .ui.secondary.inverted.menu .link.item:hover,
    .ui.secondary.inverted.menu a.item:hover {
        background: rgba(0, 0, 0, 0.08) !important;
    }

    .ui.secondary.inverted.menu .active.item {
        color: var(--primary-color) !important;
        // background-color: #18639d29 !important; ?/TODO: remove once AR and CT agree on coloring
        background-color: #bed6e9aa !important;
    }

    .ui.menu .active.item {
        font-weight: 800 !important;
    }

    .ui.secondary.inverted.menu {
        align-items: center;
    }

    .ui.menu:last-child {
        margin: auto !important;
        margin-right: 0 !important;
    }
}

.contact,
.contact-light {
    margin-left: 20px !important;
    height: 30px !important;
    width: 100px !important;
    font-weight: 600 !important;
    padding: 3px 0 0 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #18639d !important;
    color: white !important;
    font-family: 'Inter' !important;
    font-size: 0.9rem !important;
}

.contact-light:hover {
    background-color: #2287d5 !important;
    color: #ffffff !important;
    font-weight: 800 !important;
}

.contact:hover {
    background-color: #fff !important;
    color: #18639d !important;
    font-weight: 800 !important;
}
