.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
}

.headline {
    position: relative;
    height: 30vh;
    width: 100vw;
    background-color: var(--tertiary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;


    .textContainer {
        width: 55%;
        display: flex;
        height: -100px;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h2 {
            font-family: 'Inter', sans-serif;
            font-weight: 700;
            font-size: 2.5rem;
            width: 100%;
            margin-top: 0 auto;
            text-align: center;
            color: #ffffff;
            z-index: 5;
        }

        .backgroundImage {
            position: absolute;
            z-index: 3;
            top: -15%;
            left: 50%;
            transform: translateX(-60%);
            transform: scale(1.1);
            opacity: 0.2; 
        }
    }

    .particles {
        position: absolute !important;
        left: 0 !important;
        opacity: 0.2;
        top: -110px;
        z-index: 1;
        width: 100vw;
        object-fit: cover;
        height: 130%;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1280px;
}

.video {
    display: flex;
    position: relative;
    z-index: 3;
    width: 95%;
    max-width: 1100px;
    justify-content: center;
    align-items: center;

    video {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
            rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
}


.demo {
    max-width: 95% !important;
    object-fit: contain;
    margin-top: -200px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 5px;
    margin-bottom: 100px;
}

.description {
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 1050px;
}

.graphic {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.descriptionContainer {
    display: flex;
    margin: 50px 0;
    padding: 20px 0;
    flex: 1;
    width: 100%;
    justify-content: center;
    background-color: #161616;
    max-height: 380px;
    background-image: url('../../assets/images/textures/particles.svg');
}

.graphic {
    flex: 1.3;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        transform: scale(0.85) translateY(-25px) translateX(-105px) !important;
    }
}

.descriptionText {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    padding-right: 0;
    align-items: center;

    p {
        max-width: 500px;
        margin-bottom: 1.5em;
        color: #dddddd;
        font-family: 'Inter', sans-serif;
        font-size: 1.1rem;
        line-height: 1.5;
    }
}

.pillars {
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
    margin-top: 50px;
    background-color: var(--tertiary-color);
    background-image: url('../../assets/images/conf-bg-darker.jpg');
    background-size: contain; 
    background-position: center;
    background-repeat: repeat-x;

    .pillarText{
        margin-top: 30px;
        margin-bottom: 30px;
        max-width: 1280px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2 {
            color: var(--accent-color);
            font-weight: 700;
            font-family: 'Inter', sans-serif;
            margin-bottom: 0;
        }

        p {
            color: white;
            padding-bottom: 30px;
            font-size: 1.05rem;
        }
    }
}

.collegeContentContainer {
    display: flex;
    padding: 20px 0;
    flex: 1;
    width: 100%;
    justify-content: center;
    background-color: white;
}

.collegeContent {
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 1150px;
}

.collegeDescription {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 2em;
    justify-content: center;
    
    h2 {
        color: var(--accent-color);
        font-weight: 800;
        font-size: 2.2rem;
        font-family: 'Inter', sans-serif;
        margin-bottom: 0;
        margin-bottom: 15px;
    }

    p {
        max-width: 500px;
        margin-bottom: 1em;
        color: var(--tertiary-color);
        font-family: 'Inter', sans-serif;
        font-size: 1.1rem;
        line-height: 1.5;
    }
}

.collegeVideoContainer {
    flex: 1.25;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        padding: 1.3em;
        width: 100%;
    }
}

.cfbSpan {
    font-weight: 900;
    background-image: url('../../assets/images/textures/marker-smudge.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding-left: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 25px;
}