.container {
    min-height: 100%;
    background-color: #f9f9fb;
    justify-content: center;
    display: flex;
    flex: 1;
}

.bg {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 1;
    object-fit: cover;
    height: 110%;
    width: 100vw;
    filter: brightness(110%);
}

.children {
    flex: 1;
    display: flex;
    width: 90%;
    align-items: center;
    flex-direction: column;
    padding-top: 20px;
    position: relative;
    z-index: 2;

    h1 {
        text-transform: uppercase;
        font-family: 'Open Sans', sans-serif;
        font-size: 1.9rem;
        font-weight: 800;
        color: var(--tertiary-color);
        margin: 0;
    }

    h3 {
        margin-top: 20px;
        color: rgb(122, 122, 122);
        font-weight: 600;
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        text-align: center;
    }
}

.contactContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 95%;
    max-height: 800px;
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px,
        rgba(17, 17, 26, 0.1) 0px 24px 80px;

    h2 {
        color: white;
        margin: 0;
        font-size: 1.4rem;
    }

    h3 {
        color: rgb(175, 175, 175);
        margin: 5px 0 15px 0;
        font-size: 0.85rem;
        text-align: left;
    }

    .detail {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: 5px;

        a {
            color: white;
        }

        a:hover {
            color: #2287d5;
        }

        p {
            font-family: 'Open Sans', sans-serif;
            color: whitesmoke;
            margin-left: 15px;
        }
    }
}

.digitalCard {
    margin: 5px;
    background-color: var(--tertiary-color);
    height: 320px;
    border-radius: 15px;
    position: relative;
    overflow: hidden;

    .content {
        padding: 2em;

        h2 {
            color: whitesmoke;
            font-size: 1.3rem;
        }

        p {
            color: rgb(204, 204, 204);
            font-weight: 400;
            font-size: 0.9rem;
            font-family: 'Inter', sans-serif;
        }
    }
}

.contactCard {
    display: flex;
    flex-direction: row;
    padding: 0 1em 0em 1em;

    img {
        width: 100px !important;
        object-fit: contain;
        margin-right: 15px;
    }
}

.circle {
    width: 250px !important;
    position: absolute !important;
    right: -115px !important;
    bottom: -115px !important;
    opacity: 0.1;
}

.circleTwo {
    width: 80px !important;
    position: absolute !important;
    right: 50px !important;
    bottom: 70px !important;
    opacity: 0.1;
}

.social {
    color: white !important;
    margin: 0 4px 0 4px !important;
}
.social:hover {
    color: #2287d5 !important;
}

.formContainer {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    padding: 15px 0 15px 0;
    margin-bottom: 10px;
}

.form {
    position: relative;
    z-index: 10;
    width: 90%;
    height: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-between;

    h3 {
        margin-bottom: 0;
        margin-top: 10px;
        font-size: 0.9rem;
        text-align: left;
    }
}

.title {
    color: #2287d5;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    text-align: center;
}

.success {
    text-align: center;
    color: rgb(43, 43, 43);
    font-family: 'Inter', sans-serif;
}

.header {
    color: white;
    font-weight: 800;
    font-size: 2rem;
    font-family: 'Inter', sans-serif;
    text-align: center;
}

.input {
    input {
        border-radius: 0 !important;
        font-family: 'Inter', sans-serif !important;
        font-weight: 500 !important;
        font-size: 1.1rem;
        color: rgb(51, 51, 51) !important;
        height: 30px;
        padding: 0 !important;
        border: none !important;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1) !important;
    }
    width: 100% !important;
    border-radius: 0 !important;
    margin-bottom: 5px;
}

.submit {
    width: 140px;
    background-color: var(--tertiary-color) !important;
    color: white !important;
    text-transform: uppercase !important;
    font-family: 'Inter', sans-serif !important;
    font-weight: 600 !important;
    height: 40px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 5px !important;
    margin-top: 5px !important;
    align-self: flex-end;
    justify-self: flex-end;
    margin-right: 0 !important;
}

.submit:hover {
    background-color: var(--primary-color) !important;
    color: white !important;
}

.multiselect {
    margin-bottom: 40px;
}
