.container {
    display: flex;
    flex: 1;
    padding: 40px 5% 40px 5%;
    width: 100%;
    background-color: #40a2e3;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}

.bg {
    position: absolute !important;
    left: 0 !important;
    opacity: 1;
    z-index: 1;
    width: 100vw;
    object-fit: cover;
    height: 100%;
    max-height: 100%;
}

.children {
    width: 100%;
    max-width: 900px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}

.headline {
    font-family: 'Roboto', sans-serif;
    color: #023c6b;
    font-weight: 800;
    font-size: 2.5rem;
    line-height: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0px 50px 0px;
}

.video {
    display: flex;
    position: relative;
    z-index: 3;
    width: 95%;
    max-width: 800px;
    justify-content: center;
    align-items: center;

    video {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}

.content span {
    font-size: 1.4rem !important;
    font-weight: 800 !important;
}

.pillars {
    margin-top: 60px;
    display: flex;
    flex: 1;
    flex-direction: row;
    width: 100%;
    height: 300px;
}

.pillar {
    flex: 1;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1em;
    margin: 0 2em 0 2em;
    background-color: var(--tertiary-color);
    border-radius: 10px;

    h2 {
        color: rgb(230, 230, 230);
        font-family: 'Inter', sans-serif;
        font-weight: 800;
        font-size: 1.6rem;
    }

    p {
        color: #b5bfc4;
        text-align: center;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        font-size: 0.9rem;
        max-width: 180px;
    }
}

.content {
    margin-top: 60px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
    max-width: 750px;

    h2 {
        color: var(--primary-color);
        font-family: 'Inter', sans-serif;
        font-weight: 800;
        font-size: 1.4rem;
        text-transform: uppercase;
    }

    span {
        color: var(--primary-color);
        font-weight: 800;
    }

    p {
        text-align: justify;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 1.2rem;
        max-width: 900;
    }
}
