.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1280px;
}

.video {
    display: flex;
    position: relative;
    z-index: 3;
    width: 95%;
    max-width: 1100px;
    justify-content: center;
    align-items: center;

    video {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
            rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }
}

.demo {
    max-width: 95% !important;
    object-fit: contain;
    margin-top: -200px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 5px;
    margin-bottom: 50px;
}

.description {
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 1150px;
}

.descriptionContainer {
    display: flex;
    padding: 20px 0;
    flex: 1;
    width: 100%;
    justify-content: center;
    background-color: var(--tertiary-color);
}

.graphic {
    flex: 1.25;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        padding: 1.3em;
        width: 100%;
    }
}

.descriptionText {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 2em;
    justify-content: center;
    
    h2 {
        color: var(--accent-color);
        font-weight: 800;
        font-size: 2.2rem;
        font-family: 'Inter', sans-serif;
        margin-bottom: 0;
        margin-bottom: 15px;
    }

    p {
        max-width: 500px;
        margin-bottom: 1.5em;
        color: #ffffff;
        font-family: 'Inter', sans-serif;
        font-size: 1.1rem;
        line-height: 1.5;
    }
}

.pillars {
    max-width: 1050px;
    width: 90%;
    padding-top: 50px;
    padding-bottom: 50px;

    h2 {
        color: var(--primary-color);
        font-weight: 700;
        font-family: 'Inter', sans-serif;
        margin-bottom: 0;
    }

    p {
        padding-bottom: 30px;
    }
}

.transferPortal {
    display: flex;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #ffffff;
    justify-content: center;

    h2 {
        color: var(--accent-color);
        font-weight: 800;
        font-size: 2.2rem;
        font-family: 'Inter', sans-serif;
        margin-bottom: 0;
        margin-bottom: 15px;
    }

    p {
        padding-bottom: 30px;
    }
}

.transferPortalText {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: 30px;
}

.transferPortalContent {
    width: 90%;
    max-width: 1250px;

    p {
        max-width: 450px;
        margin-bottom: 1.5em;
        color: var(--tertiary-color);
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        line-height: 1.5;
        margin-bottom: 0;
        padding-bottom: 20px;
    }
}

.transferPortalContentContainer {
    display: flex;
    justify-content: space-between;
}

.mainImg {
    width: 750px;
    object-fit: contain;
}

.rosterManagement {
    display: flex;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #ffffff;
    justify-content: center;

    h2 {
        color: var(--accent-color);
        font-weight: 800;
        font-size: 2.2rem;
        font-family: 'Inter', sans-serif;
        margin-bottom: 0;
        margin-bottom: 15px;
    }

    p {
        padding-bottom: 30px;
    }
}

.rosterManagementText {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    justify-content: right;
}

.rosterManagementContentContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 90%;

    p {
        max-width: 450px;
        margin-bottom: 1.5em;
        color: var(--tertiary-color);
        font-family: 'Inter', sans-serif;
        font-size: 1rem;
        line-height: 1.5;
        margin-bottom: 0;
        padding-bottom: 20px;
    }
}

.rmMainImg {
    padding-top: 30px;
    width: 900px;
    object-fit: contain;
}   


.verticalImg {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 250px !important;

    img {
        height: 53px !important;
    }
}

.headline {
    width: 100%;
    height: 300px;
    background-color: var(--tertiary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    

    h1 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 1.4rem;
    }

    h2 {
        font-family: 'Inter', sans-serif;
        font-weight: 800;
        font-size: 1.8rem;
        margin-top: 0;
        text-align: center;
        color: #ffffff;
        z-index: 2;
    }

    img {
        position: absolute !important;
        left: 0 !important;
        opacity: 0.3;
        top: -150px;
        z-index: 1;
        width: 100vw;
        object-fit: cover;
        height: 450px;
    }
}

.dots {
    position: absolute !important;
    left: 0 !important;
    opacity: 0.015;
    z-index: 1;
    width: 100vw;
    object-fit: cover;
    height: 100%;
    max-height: 100%;
}