div.page-segment.ui.placeholder.segment {
    // min-height: 100vh;
    margin: unset;
    justify-content: unset;
    border-radius: 0;
    padding: 0px;
    width: 100vw !important;
    flex: 1 1 auto;
}

.app-base {
    font-family: var(--font) !important;
    color: var(--font-color);
    height: 100vh;
}

.track-horizontal {
    position: absolute;
    height: 6px;
    right: 2px;
    top: 10px;
    left: 2px;
    border-radius: 3px;

    .thumb-horizontal {
        position: relative;
        display: block;
        height: 100%;
        cursor: pointer;
        border-radius: inherit;
        background-color: #8fa1b7; // changed the color
    }
}

.track-vertical {
    position: absolute;
    width: 6px;
    right: 2px;
    bottom: 2px;
    top: 10px;
    border-radius: 3px;

    .thumb-vertical {
        position: relative;
        display: block;
        height: 100%;
        z-index: 2;
        cursor: pointer;
        border-radius: inherit;
        background-color: #8fa1b7;
    }
}

.track-view {
    margin-top: 20px !important;
}

div.ui.header {
    // color: var(--primary-color);
    font-family: var(--font);
}

p {
    // color: var(--primary-color);
    font-family: var(--font);
}

div.ui.label {
    font-family: var(--font);
    color: var(--font-color);
}

div.field {
    label {
        font-family: var(--font) !important;
        color: var(--font-color) !important;
    }
}

@media print {

    .no-print,
    .no-print * {
        display: none !important;
    }
}

div.ui.selection.dropdown {
    color: var(--secondary-color);
    padding-right: 1%;

    i {
        float: right;
        position: relative;
        z-index: 3;
    }
}

div.ui.fluid.dropdown {
    color: var(--secondary-color);
    padding-right: 1%;

    i {
        float: right;
        position: relative;
        z-index: 3;
    }
}

body {
    -webkit-print-color-adjust: exact !important;
}

.page {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);

    .page-menu {
        margin: 1% !important;
        margin: 0px !important;

        .page-header {
            font-family: var(--font);
            // color: var(--primary-color);
            font-size: 20px;
            height: 30px;
            font-weight: bold;
            letter-spacing: 0ch;
            line-height: 30px;
        }

        .page-item {
            font-family: var(--font);
            color: #8fa1b7 !important;
            font-size: 14px;
            line-height: 30px;

            &.dropdown {
                padding: 0 !important;
            }

            &.dropdown.active {
                border-bottom-width: 0 !important;
            }
        }

        .active.item.page-item {
            font-family: var(--font);
            color: var(--secondary-color) !important;
            border-color: var(--secondary-color) !important;
            font-size: 14px;
            line-height: 30px;

            .ui.item.dropdown.page-item {
                color: var(--secondary-color) !important;
                font-family: var(--font);
                font-weight: bold;
            }
        }
    }

    width: 100vw !important;
}

div.view-container {
    margin-left: 0px !important;
    margin-right: 0px !important;
    display: flex !important;
    flex: 1 1 auto;
    align-items: stretch !important;
    justify-content: stretch !important;
    margin: 0px !important;
}