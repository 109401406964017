.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: -115px;
    padding-top: 115px;
}

.hero {
    height: 100vh;
    margin-top: -115px;
    display: flex;
    flex-direction: column;
    padding-top: 40vh;
    align-items: center;
    // background-image: url('https://s3.amazonaws.com/scratch.telemetry.fm/ar/telemetry-website/playcard.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.headerTop {
    z-index: 2;
    font-family: 'Inter';
    color: #2f9ff5;
    font-weight: 800;
    text-shadow: 0px 0px 20px #000000;
    text-transform: uppercase;
    font-size: 1.8vw;
    margin-bottom: 30px;
}

.headerBottom {
    z-index: 2;
    font-family: 'Inter';
    color: #fff;
    text-shadow: 2px 2px 30px #011627;
    font-weight: 400;
    font-size: 3.2vw;
    text-align: center;
    line-height: 0.9;
}
