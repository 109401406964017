.wrapper {
    position: relative;
    padding-top: min(56.25%, 55vh);
    margin-left: calc((100vw - calc(min(56.25%, 55vh) * 16 / 9)) / 2 - 40px);
    max-width: calc(min(56.25%, 55vh) * 16 / 9);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.player {
    position: absolute;
    top: 0;
    left: 0;
}
